import * as React from "react"
import styled from "styled-components"
import Layout from "../../../components/layout"
import PPLogo from "../../../images/pp/head/tp/p.inline.svg"
import Logo from "../../../images/pp/head/tp/cp_logo.inline.svg"
import { Link } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import Projects from "../../../components/pp_projects"
import textpayChart from "../../../images/pp/textpay_chart.png"
import textpayCJM from "../../../images/pp/textpay_cjm.png"
import textpayChartSm from "../../../images/pp/textpay_chart_sm.png"
import jobsMap from "../../../images/pp/jobs_map.png"
import Footer from '../../../components/footer';
import screen2 from "../../../images/pp/enforcement_protection.png"
import screen4 from "../../../images/pp/insurance.png"

import screen5 from "../../../images/pp/products.png"
import screen6 from "../../../images/pp/condo_overfilled.png"
import screen7 from "../../../images/pp/verify_insurance.png"
import screen8 from "../../../images/pp/travel_product.png"

import ProductCase1 from "../../../images/pp/case_1.png"
import ProductCase2 from "../../../images/pp/case_2.png"
import ProductCase3 from "../../../images/pp/case_3.png"

import screen10 from "../../../images/pp/condo.png"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Wrapper from '../../../components/wrap';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import "../../../slider.css"
import Slider from "react-slick"

// markup
const IndexPage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  return (
    <Layout navtype="dark" title="Premium Parking">
      <Header>
        <AnimationOnScroll animateIn="animate__fadeInRight" className="pp-logo" animateOnce="true" offset={0}>
          <PPLogo />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInLeft" className="tp-logo" animateOnce="true" offset={0}>
          <Logo />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeIn" className="camera" animateOnce="true" offset={0}>
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/head/tp/photo.png"
            alt="Photo"
            layout="constrained"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInDown" className="ep" animateOnce="true" offset={0}>
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/head/tp/ep.png"
            alt="Photo"
            layout="constrained"
          />
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__fadeInUp" className="lp" animateOnce="true" offset={0}>
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/head/tp/lp.png"
            alt="License Plate"
            layout="constrained"
            />
        </AnimationOnScroll>
      </Header>
      <AnimationOnScroll animateIn="animate__wrapInUp" animateOnce="true">
      <Wrapper className="first">
        <h1>
          <small>Premium Parking</small>
          Rethinking checkout flow for upsell products  in TextPay and CameraPay</h1>
        <ul className="types">
          <li>
            <small>Timeframe</small>
            <br />
            Aug 2021-Jul 2022
          </li>
          <li>
            <small>Role</small>
            <br />
            Lead Product Designer
          </li>
          <li>
            <small>Responsibilities</small>
            <br />
            Research, UX Design, Usability Testing, Development hand-off
          </li>
          <li className="full">
            <small>Team</small>
            <br />
            Company President, Director of Business Intelligence, Director of Product Experience, Lead Product Designer (me), 2 Front-End Developers, Development Team Lead, 2 Back-end Engineers, Project Manager
          </li>
        </ul>
        <br />
        <p>
          <a href="https://premiumparking.com" target="_blank" rel="noreferrer">Premium Parking</a> is digital parking solution in the US, with over 500 employees and $105.7M in annual revenue.
          <a href="https://textpay.premiumparking.com" target="_blank" rel="noreferrer">TextPay</a> and <a href="https://camerapay.premiumparking.com/vehicle/P110" target="_blank" rel="noreferrer">CameraPay</a> are simplified versions of the web application of Premium Parking, oriented to checkout-flow. Works anywhere with instant launch without any downloads.
        </p>
        <strong className="sub-title">Challenges and goals</strong>
        <h3>Increase revenue by upselling products from partners to customers</h3>
        <ol>We had goals:
          <li>Integration with Travel provider</li>
          <li>Integration with Insurance provider</li>
          <li>Convert customers into loyal clients</li>
          <li>Establish flexible checkout options for varying rates and product combinations</li>
          <li>Ensure consistency with other premium products</li>
        </ol>
        <p>
          Besides, the next big challenge was the launch of a CameraPay, based on TextPay. The difference between products in auth flows. In CameraPay the process starts with scanning the location's QR Code. So, we know the location number, and we need to identify the customer or not.
          <br />
          <a href="#results">View Results</a>
        </p>

        <strong className="sub-title">My role and the team</strong>
        <h3>Holistic approach for a complex challenge</h3>
        <p>
          The team was well versed in the product, design system, and code base. 
          I was responsible for all design initiatives. I mapped out the user's journey, designed ideas, user validation, iteration, prototyping, and crafted high fidelity prototypes throughout all stages of the project. Also, I directly collaborated with partner's teams to understand integrations requirements.
        </p>
        <strong className="sub-title">Design Process</strong>
        <h3>Research</h3>
        
        <p><em>Just suggesting the partner's products for customers during the checkout flow wouldn't bring the 100% desired outcome. It had to be a coordinated effort in multiple phases of the checkout steps.</em></p>
        <p>
          <strong>What we already have</strong>
          <br />
          Customers send a text message with a location number (ex: P110) to the SMS gateway. The platform identifies the customer by phone number and sends back a link to a simplified checkout flow.</p>
        <p>First, I looked at what already exists in the old version of TextPay. TextPay has lots of design issues, but it should not interfere with analyzing the it.</p>
        <div className="history history-mobile">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old1.png"
            alt="Map"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old2.png"
            alt="Map"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old3.png"
            alt="Map"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/textpay_old4.png"
            alt="Map"
          />
        </div>
        <p><small>Examples of the previous version of TextPay</small></p>
        <p>
          <strong>Feedback</strong>
          <br/>
          I have gathered the feedback and most common issues from customers which collected in freshdesk. 
        </p>
        <ol>
          <li>Customers made mistakes with parking lot numbers</li>
          <li>Customers don't remember their vehicle license plate numbers</li>
          <li>No signal on the underground parkings</li>
          <li>No convenient payment methods (Apple Pay, Google Pay)</li>
          <li>Inconvenient to fill each time payment & vehicle info</li>
        </ol>
        
        <p>
          <strong>Statistics</strong>
          <br/>
          We had some data insights from the BI team, Google Analytics, and specific experiments that was successful on mobile and web apps. I extracted useful data:</p>
        <ol>
          <li>The majority (75%) of TextPay users have 1–2 transactions</li>
          <li>32% of users extend sessions</li>
          <li>12% of users residents from another state/country</li>
        </ol>
        <p>So, about 25% of users can be converted to mobile app users, and get more information about them later. Also, increased mobile apps users would reduce the cost of reminders and could increase engagement.</p>
        <p>
          Residents from another states and users with 1-2 transactions (road trippers) are potential users of Travel products.
        </p>
        <strong>User stories</strong>
        <ul>
          Thanks to detailed feedback, I have formulated user stories
          <li>As a user, I want to provide my vehicle information during the parking reservation process, ensuring that the parking space is suitable for my vehicle</li>
          <li>As a user, I want to have options for choosing the duration of my parking session, giving me flexibility based on my needs</li>
          <li>As a user, I want to be able to select various payment options for my parking session, making it convenient and secure</li>
          <li>As a user, I want a user-friendly interface that allows me to correct any typos or errors in the reservation process</li>
          <li>As a user, I want the parking reservation process to be a coordinated effort, with multiple steps to ensure a seamless experience</li>
          <li>As a user, I don't want to make mistakes when entering parking location number or vehicle information</li>
          <li>As a user, I want to be notified if there is no internet connection during the parking reservation process, ensuring that I am aware of any potential issues that could affect the completion of my reservation</li>
          <li>As a user, I don't want to fill in Vehicle information each time</li>
        </ul>
        <ul>
          And new ones for partner products
          <li>As a user, I want to be covered with a vehicle insurance option during the parking session</li>
          <li>As a user, I want to buy tickets for nearby travel activities during the parking reservation process, providing me with convenient options to explore the area during my visit</li>
        </ul>
        <p>
          We started with dividing core flow into processes and then went through each process step by step. I've prepared Jobs Map to dig into the desired outcomes and challenges faced for more insight into what impacts the job to be done. The jobs map helped take the customer’s perspective, rather than stack on solutions from partners and old version of the TextPay.
        </p>
      </Wrapper>
        <div className="wide-scheme">
          <InnerImageZoom src={jobsMap} zoomSrc={jobsMap} className="img-with-theme-support" />
        </div>
      <Wrapper className="first">
        <h3>Designing the solutions</h3>
        <p>Thankfully, we had already the Premium Design System, which we build with Southleft during <Link to="/projects/premium-parking/rebrand">rebrand</Link>. So, having a jobs map, user stories and the design system, I was able to quickly build a prototype of the core flow.</p>
        <p>I upgraded numerous scenarios during the design phase, introducing new states and components. The primary focus was on refining the duration and Vehicle screens, as well as streamlining the authorization flow.</p>
        <strong>Just some design details</strong>
        
        <div className="screen-details">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/vehicle_old.png"
            alt="Old Vehicle Information"
            className="old"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/vehicle_new.png"
            alt="New Vehicle Information"
            className="new"
          />
          <ul className="improvements">
            <strong>Updates to the Vehicle Info screen</strong>
            <li>Removed the signup link; we detect or create an account during the initial step</li>
            <li>Added a field for entering the License Plate Number as input</li>
            <li>Included License Plate State, reducing confusion in cases where identical numbers exist</li>
            <li>Enhanced the visibility of steps (3-4, depending on the location)</li>
            <li>Added an option for users who do not remember their License Plate Number</li>
            <li>Improved consistency with other Premium products</li>
            <li>Display saved vehicles if an account already exists</li>
            <li>Resolved accessibility issues</li>
          </ul>
        </div>

        <div className="screen-details">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/duration_old.png"
            alt="Old Duration Screen"
            className="old"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/duration_new.png"
            alt="New Duration Screen"
            className="new"
          />
          <ul className="improvements">
            <strong>Improvements to the Duration screen</strong>
            <li>Moved 'Apply Promo Code' to the Payment Screen</li>
            <li>Added the option to view and modify details from previous steps</li>
            <li>Included the option to go back to the previous step</li>
            <li>Clarified the call-to-action (CTA) 'View Rate'</li>
            <li>Added the option for Monthly Parking, redirecting to the Web app</li>
            <li>Enhanced the visibility and clarity of steps</li>
            <li>Resolved accessibility issues</li>
            <li>Increased consistency with other Premium products</li>
          </ul>
        </div>

        <div className="screen-details">
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/payment_old.png"
            alt="Old Vehicle Information"
            className="old"
          />
          <StaticImage
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            src="../../../images/pp/payment_new.png"
            alt="New Vehicle Information"
            className="new"
          />
          <ul className="improvements">
            <strong>Improvements to the Payment screen</strong>
            <li>Added more convenient payment methods: Apple Pay, Google Pay</li>
            <li>Included a summary for review before payment</li>
            <li>If payments methods are not detected, we will display the credit card fields</li>
            <li>Customers are now able to view the summary and payment buttons on a single screen</li>
            <li>Added tax and fee rows</li>
          </ul>
        </div>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
        <div className="wide-scheme wide-scheme--filled">
          <span className="scheme-label-wrap">
            <span className="scheme-label">TextPay All Steps</span>
          </span>
          <InnerImageZoom src={textpayChartSm} zoomSrc={textpayChart} />
        </div>
      </AnimationOnScroll>
      <Wrapper>
        <ul>
          What has been done at the first iteration:
          <li>Added app download suggestions without losing any entered information, and offered discounts through the wallet to encourage customer migration to the mobile app</li>
          <li>Allow payment for parking without creating an account</li>
          <li>Reserved a "space" for Travel Partner's products</li>
          <li>Suggested nearby locations during blackout situations</li>
          <li>Added Apple/Google Pay as payment methods</li>
          <li>Added parking space types with an explanation of the differences</li>
          <li>Enabled customers to modify entered information at any stage</li>
          <li>Allocated "space" for additional information and service messages</li>
          <li>Enabled payment for parking without entering a license plate number</li>
          <li>Fixed a dead-end scenario if the client used the old link. Users had to resend a text message, which was generally not obvious. Additionally, nothing could be done using the link itself</li>
        </ul>
        <p>
          I conducted prototype testing sessions with small businesses and Premium staff who use TextPay or mobile apps daily, in order to validate the initial design and hypotheses. The findings were thoroughly analyzed, leading to further adjustments in Figma.
          <br/>
          Key performance indicators (KPIs) evaluated included step completion, success rate, and time spent on each step.
        </p>
        <p>
          Subsequently, I dedicated my efforts to detailed work involving Travel Partner's products. Following an API investigation, we identified three distinct product types, for each of which I developed prototypes. Additionally, I made several adjustments following the second round of testing.
        </p>
      </Wrapper>
      <Cases>
        <Slider {...settings}>
          <div className="case-1">
            <InnerImageZoom src={ProductCase1} zoomSrc={ProductCase1} />
            <div className="case-note">
              <strong>Case #1</strong>
              Start Date = Parking Date, Start time = several options, Duration = several options depends on Start Time
            </div>
          </div>
          <div className="case-2">
          <InnerImageZoom src={ProductCase2} zoomSrc={ProductCase2} />
            <div className="case-note">
              <strong>Case #2</strong>
              No options to choose a start time and duration: Start Date = Parking Date, Start time = one option, Duration = one option
            </div>
          </div>
          <div className="case-3">
            <InnerImageZoom src={ProductCase3} zoomSrc={ProductCase3}/>
            <div className="case-note">
              <strong>Case #3</strong>
              No options to choose duration: Start Date = Parking Date, Start time = several options, Duration = one option
            </div>
          </div>
        </Slider>
      </Cases>
      <Wrapper>
        <p>
          It was important to consider that previously there were no branded locations. So, I've prepared the high fidelity prototypes for different branded locations to show and confirm with clients before upcoming updates.
        </p>
        <StaticImage
          placeholder="blurred"
          formats={["AUTO", "WEBP", "AVIF"]}
          src="../../../images/pp/branded_textpay.png"
          alt="Map"
        />
        <p>
        <strong>The core hypothesis of CameraPay</strong>
        <br/>
        Customers frequently made errors when entering location numbers (due to typos, autocorrect, or sending incorrect numbers), resulting in potential penalties. To mitigate this, we considered hard coding the location number in the QR code, necessitating the need to request the phone number. As a result, the disparities primarily existed in the header and the initial launch screen. This facilitated a rapid and straightforward design and launch process for CameraPay.
        </p>
      </Wrapper>
      <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
        <div className="wide-scheme">
          <InnerImageZoom src={textpayCJM} zoomSrc={textpayCJM} />
        </div>
      </AnimationOnScroll>
      <Wrapper>
        <h3>Launch and build at scale</h3>
        <p>
          After a successful launch, I continued to design new integrations, which helped to continue the growth.
          The next integration was Localize, which helped to improve communication with non-English speaking customers, launched on CameraPay and TextPay.
          <br />
          The next integrations were launched across all products oriented to customers (TextPay, CameraPay, Web, iOS, and Android apps). It was possible to launch in a short time, because before we have reworked all platforms for flexible integrations. 
          <br />
          It always started with exploring the capabilities of the API with the development team. Then, I added new cross-platform components to the design system and expanded the existing ones. Next, I designed all the scenarios for possible tests, and refined them.
        </p>
        <p>
          Next integrations were condo hotels, insurance (Neosurance), and enforcement protection. Here are just some screens of TextPay and CameraPay.</p>
      </Wrapper>
      <div className="screens-grid">
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce="true">
            <img src={screen5} alt="No License Plate" />
            <img src={screen2} alt="Saved Vehicle" />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <img src={screen10} alt="Travel" />
            <img src={screen6} alt="Auth" />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeIn" animateOnce="true">
            <img src={screen4} alt="Insurance" />
            <img src={screen7} alt="License Plate Detection" />
          </AnimationOnScroll>
        </div>
        <div className="col">
          <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce="true">
            <img src={screen8} alt="Choose Space Type" />
          </AnimationOnScroll>
        </div>
      </div>
      <Wrapper id="results">
        <h3>Outcomes</h3>
        <ol>
          <li>Implemented 5 new integrations (<a href="https://www.peek.com/" target="_blank" rel="noreferrer">Peek Travel</a>, City Sightseeing, <a href="https://neosurance.it" target="_blank" rel="noreferrer">Neosurance</a>, Condo Hotels, Enforcement Protection)</li>
          <li>12% of new mobile app users originated from TextPay/CameraPay</li>
          <li>Achieved a 20% rise in revenue in the first month after launch</li>
          <li>Transitioned to the Premium Design System</li>
        </ol>
        <p>
          View live <a href="https://textpay.premiumparking.com" target="_blank" rel="noreferrer">TextPay</a> or <a href="https://camerapay.premiumparking.com/vehicle/P110" target="_blank" rel="noreferrer">CameraPay</a>
        </p>
      </Wrapper>
      <Projects project="textpay" />
    </AnimationOnScroll>
  <Footer />
</Layout>
  )
}

const Cases = styled.div`
  .slick-dots li {
    background: #2377e633;
    border-radius: 10px;
    border: solid 3px #fff;
  }

  .slick-dots .slick-active {
    background: var(--color-primary);
  }
  
  .slick-prev {
    left: 20px;
  }

  .slick-next {
    right: 20px;
  }
`
const Header = styled.div`
  background: #D7262E;
  padding: 100px 0;
  text-align: center;
  position: relative;

  .gatsby-image-wrapper {
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0 0 28px 8px rgb(0 0 0 / 10%);
    position: relative;
    z-index: 9;
  }

  .ep {
    position: absolute;
    top: 0;
    right: 30px;
    max-height: 50%;
    width: 19%;
  }

  .lp {
    position: absolute;
    bottom: 0;
    right: 17%;
    max-height: 50%;
    width: 19%;
  }

  .pp-logo {
    position: absolute;
    right: 0;
    top: 43%;
    max-width: 50%;
  }

  .tp-logo {
    left: 50px;
    position: absolute;
    top: 50%;
    margin-top: -150px;
    max-width: 50%;
  }

  svg {
    max-width: 100%;
  }

  .camera {
    width: 19%;
    height: auto;
    margin: auto;
    z-index: 9;
  }

  @media (max-width: 1024px) {
    padding: 50px 0;

    .tp-logo {
      left: 10px;
    }
  }

  @media (max-width: 550px) {
    padding: 20px 0;
  }

  @media (max-width: 400px) {
    padding: 10px 0;
    
    .tp-logo {
      left: 0;
    }
  }
`

export default IndexPage
